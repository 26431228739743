<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="82px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业注册地">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              filterable
              placeholder="输入关键词可搜索"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="人员姓名">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入人员完整姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="企业名称">
            <el-input
              v-model.trim="form.companyName"
              placeholder="请输入企业名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="注册号">
            <el-input
              v-model.trim="form.regNo"
              placeholder="请输入注册号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="人员证书" style="margin-bottom: 0px">
            <div
              class="flex mb22"
              v-for="(item, index) in personNoType"
              :key="'project' + index"
            >
              <div class="flex1 mr20">
                <el-cascader
                  v-model="item.value"
                  :options="personNoTypeOptions"
                  :props="{
                    value: 'allDesc',
                    label: 'desc',
                    children: 'children',
                    emitPath: false,
                  }"
                  clearable
                  filterable
                  placeholder="输入关键词可搜索"
                ></el-cascader>
              </div>

              <div>
                <el-button
                  v-if="index == 0"
                  type="primary"
                  icon="el-icon-plus"
                  plain
                  circle
                  @click="addPersonNo"
                >
                </el-button>

                <el-button
                  v-else
                  @click="delPersonNo(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          位符合的人员
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="flex alc jcsb mb20">
            <div class="name">
              {{
                `${
                  item.name +
                  (item.companyName && item.companyName != "-1"
                    ? "-" + item.companyName
                    : "")
                }`
              }}
            </div>

            <div class="c666">
              身份证号：
              {{ item.idNo | noVoid }}
            </div>
          </div>

          <div class="desc">
            <div
              class="desc_item"
              style="flex: 1.5"
              :title="`注册类别/专业：${
                item.regType && item.regType != '-1' ? item.regType : '--'
              }`"
            >
              注册类别/专业：
              <span>
                {{ item.regType | noVoid }}
              </span>
            </div>

            <div
              class="desc_item"
              :title="`证书号：${
                item.regNo && item.regType != '-1' ? item.regType : '--'
              }`"
            >
              证书号：
              <span>
                {{ item.regNo | noVoid }}
              </span>
            </div>

            <div
              class="desc_item"
              :title="`执业印章号：${
                item.regNo3 && item.regNo3 != '-1' ? item.regNo3 : '--'
              }`"
            >
              执业印章号：
              <span>
                {{ item.regNo3 | noVoid }}
              </span>
            </div>

            <div
              class="desc_item"
              :title="`有效期：${
                item.regTerm && item.regTerm != '-1' ? item.regTerm : '--'
              }`"
            >
              有效期：
              <span>
                {{ item.regTerm | noVoid }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查人员 */
// 引入ajax函数
import { searchPersonByDto } from "@/api/itemizedQuery";
import { sourceTree, getDict } from "@/api/common";
import area from "@/utils/area"; // 引入区域json
import queryTypeTips from "../components/queryTypeTips";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        location: [], // 地区
        personNoType: "", // 人员证书
        name: "", // 姓名
        companyName: "", // 公司名称
        regNo: "", // 注册号
        queryType: "like", // 检索模式
        pageNum: 1,
        pageSize: 20,
      },

      personNoTypeOptions: [], // 人员证书选项数据
      personNoType: [{ value: "" }], // 人员证书数据
      areaOptions: [], // 地区数据
      queryTypeOptions: [], // 检索模式

      list: [], // 列表
      total: 0, // 总数
      paginationTotal: 0, // 分页总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const location = this.form.location.filter((v) => v != "").join("-");
      const personNoType = this.personNoType
        .filter((v) => v.value != "")
        .map((v) => v.value)
        .join(",");

      const params = {
        ...this.form,
        location,
        personNoType,
        total: this.total,
      };

      const { data } = await searchPersonByDto(params);
      this.list = data.records;
      this.total = data.total;
      this.paginationTotal = data.total > 2000 ? 2000 : data.total;
      this.loading = false;
    },
    // 获取树形数据
    async getOptions() {
      // 人员证书
      const personNoTypeOptions = await sourceTree({
        source: "personnelNoType",
      });
      this.personNoTypeOptions = personNoTypeOptions.data;

      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions.data;
    },

    /* 操作 */
    // 添加人员证书
    addPersonNo() {
      if (this.personNoType.length < 5) {
        this.personNoType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除人员证书
    delPersonNo(index) {
      this.personNoType.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 地区
        personNoType: "", // 人员证书
        name: "", // 姓名
        companyName: "", // 公司名称
        regNo: "", // 注册号
        queryType: "like", // 检索模式
        pageNum: 1,
        pageSize: 20,
      };
      this.personNoType = [{ value: "" }];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查人员") {
        this.form.name = name;
      }
      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  activated() {
    this.__init__();
  },

  // 生命周期-创建完毕状态
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      // cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
          overflow: hidden; // 溢出隐藏
          text-overflow: ellipsis; // 溢出用省略号显示
          display: -webkit-box; // 作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
          -webkit-line-clamp: 1; // 显示的行数
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
