<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="72px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              filterable
              placeholder="输入关键词可搜索"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="姓名">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入项目经理完整姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业名称">
            <el-input
              v-model.trim="form.companyName"
              placeholder="请输入企业名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          位符合的项目经理
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="flex alc jcsb mb20">
            <div
              class="name link"
              @click="toDetail(item.companyId, item.name, item.recentResult)"
            >
              {{
                `${
                  item.name +
                  (item.regCompanyName && item.regCompanyName != "-1"
                    ? "-" + item.regCompanyName
                    : "")
                }`
              }}
            </div>

            <div class="c666">
              最近中标：
              {{ item.recentResult | noVoid }}
            </div>
          </div>

          <div class="desc">
            <div class="desc_item" style="flex: 2">
              证书编号：
              <span>
                {{ item.certificateNo | noVoid }}
              </span>
            </div>

            <div class="desc_item">
              业绩：
              <span class="highlight2">
                {{ item.resultCount | noVoidNum }}
              </span>
              个
            </div>

            <div class="desc_item">
              诚信：
              <span class="highlight2">
                {{ item.integrityCount | noVoidNum }}
              </span>
              个
            </div>

            <div class="desc_item">
              荣誉：
              <span class="highlight2">
                {{ item.honorCount | noVoidNum }}
              </span>
              个
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查项目经理 */
// 引入ajax函数
import { searchManager } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";
import queryTypeTips from "../components/queryTypeTips";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        location: [], // 地区,
        name: "", // 姓名
        companyName: "", // 企业
        queryType: "like", // 检索模式
        pageNum: 1,
        pageSize: 20,
      },

      areaOptions: [], // 地区选项数据
      queryTypeOptions: [], // 检索模式
      list: [],
      total: 0,
      paginationTotal: 0, // 分页总数
      loading: false,
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const location = this.form.location.filter((v) => v != "").join("-");

      const params = {
        ...this.form,
        location,
        total: this.total,
      };
      const { data } = await searchManager(params);
      this.list = data.records;
      this.total = data.total;
      this.paginationTotal = data.total > 2000 ? 2000 : data.total;

      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions.data;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id, name, time) {
      this.$router.push({
        name: "personDetails",
        params: { id },
        query: { name, time },
      });
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },

    // 重置
    reset() {
      this.form = {
        location: [], // 地区,
        name: "", // 姓名
        companyName: "", // 企业
        queryType: "like", // 检索模式
        pageNum: 1,
        pageSize: 20,
      };
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "项目经理") {
        this.form.name = name;
      }
      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  activated() {
    this.__init__();
  },

  // 生命周期-创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
