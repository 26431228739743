<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="72px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业名称">
            <div class="flex alc">
              <div class="mr20 flex2">
                <el-input
                  v-model="form.cname"
                  placeholder="请输入企业名称关键词"
                  clearable
                ></el-input>
              </div>

              <div class="flex1">
                <el-select
                  style="width: 100%"
                  v-model="form.ctype"
                  placeholder="请选择企业性质"
                  clearable
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="成立时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              clearable
              @change="handleTimeChange"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="注册资金">
            <div class="flex alc">
              <el-input
                v-model.number="form.cminRegCost"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number="form.cmaxRegCost"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="实缴资本">
            <div class="flex alc">
              <el-input
                v-model.number="form.cminPaidupCapital"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number="form.cmaxPaidupCapital"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业地区">
            <el-cascader
              v-model="form.clocation"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              placeholder="输入关键词可搜索"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="企业法人">
            <el-input
              v-model="form.clegalPerson"
              placeholder="请输入企业法人姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="经营范围">
            <el-input
              v-model="form.cbusinessScope"
              placeholder="支持多关键词，如：公路 道路"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="注册地址">
            <el-input
              v-model="form.cregAddress"
              placeholder="请输入企业地址关键字，如：开发区"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="合作方式">
            <el-radio-group v-model="form.cjoinIn">
              <el-radio label="">全部</el-radio>
              <el-radio label="是"> 加盟分公司 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="是否推荐">
            <el-radio-group v-model="form.crmd">
              <el-radio label="">全部</el-radio>
              <el-radio label="是">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          家符合的企业
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name">
            <span class="link" @click="toDetail(item.cid)">
              {{ item.cname | noVoid }}
            </span>

            <span v-if="item.ctype" class="tag">{{ item.ctype }}</span>
          </div>

          <div class="desc">
            <div class="desc_item">
              注册资金：
              {{ item.cregCost | wanCost }}
            </div>

            <div class="desc_item">
              业绩数量：
              {{ item.cresultCount || 0 }}
            </div>

            <div class="desc_item">
              注册地区：
              {{ item.clocation }}
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查企业 */
// 引入ajax函数
import { combinedQueryList } from "@/api/combinedQuery";
// 引入区域json
import area from "@/utils/area";

export default {
  // 数据
  data() {
    return {
      form: {
        cname: "", // 企业名称
        ctype: "", // 企业性质
        cminEstablishmentTime: "", // 成立时间-开始
        cmaxEstablishmentTime: "", // 成立时间-结束
        cminRegCost: "", // 注册资金最小值
        cmaxRegCost: "", // 注册资金最大值
        cminPaidupCapital: "", // 实缴资本最小值
        cmaxPaidupCapital: "", // 实缴资本最大值
        clocation: [], // 企业地区,
        clegalPerson: "", // 企业法人
        cbusinessScope: "", // 经营范围
        cregAddress: "", // 注册地址
        cjoinIn: "", // 合作方式
        crmd: "", // 是否推荐
        pageNum: 1,
        pageSize: 20,
      },
      time: [], // 成立时间
      areaOptions: [], // 地区数据
      // 企业类型选项数据
      typeOptions: [
        { name: "国企", value: "国企" },
        { name: "民企", value: "民企" },
      ],
      // 日期选择器配置项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [], // 列表
      total: 0, // 总数
      paginationTotal: 0, // 分页总数
      loading: false, // 加载状态
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const clocation = this.form.clocation.filter((v) => v != "").join("-");
      const cminRegCost = this.form.cminRegCost || null; // 注册资金最小值
      const cmaxRegCost = this.form.cmaxRegCost || null; // 注册资金最大值
      const cminPaidupCapital = this.form.cminPaidupCapital || null; // 实缴资本最小值
      const cmaxPaidupCapital = this.form.cmaxPaidupCapital || null; // 实缴资本最大值
      const params = {
        ...this.form,
        clocation,
        cminRegCost, // 注册资金最小值
        cmaxRegCost, // 注册资金最大值
        cminPaidupCapital, // 实缴资本最小值
        cmaxPaidupCapital, // 实缴资本最大值
      };

      const { code, data, msg } = await combinedQueryList(params);
      if (code !== 0) return this.$message.error(msg);
      this.list = data.records;
      this.total = data.total;
      this.paginationTotal = data.total > 2000 ? 2000 : data.total;
      this.loading = false;
    },

    /* 操作 */
    // 时间选择器change事件
    handleTimeChange(val) {
      if (val) {
        this.form.cminEstablishmentTime = val[0];
        this.form.cmaxEstablishmentTime = val[1];
      } else {
        this.form.cminEstablishmentTime = "";
        this.form.cmaxEstablishmentTime = "";
      }
    },
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "companyDetails",
        params: { name: "资质等级" },
        query: { id },
      });
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        cname: "", // 企业名称
        ctype: "", // 企业性质
        cminEstablishmentTime: "", // 成立时间-开始
        cmaxEstablishmentTime: "", // 成立时间-结束
        cminRegCost: "", // 注册资金最小值
        cmaxRegCost: "", // 注册资金最大值
        cminPaidupCapital: "", // 实缴资本最小值
        cmaxPaidupCapital: "", // 实缴资本最大值
        clocation: [], // 企业地区,
        clegalPerson: "", // 企业法人
        cbusinessScope: "", // 经营范围
        cregAddress: "", // 注册地址
        cjoinIn: "", // 合作方式
        crmd: "", // 是否推荐
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查企业") {
        this.form.cname = name;
      }

      this.getData();
    },
  },

  // 生命周期-实例激活状态
  activated() {
    this.__init__();
  },

  // 生命周期-创建完成状态
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;

        .tag {
          font-size: 14px;
          padding: 2px 8px 4px;
          border-radius: 4px;
          line-height: 22px;
          color: #1d89e3;
          background-color: #d7edfb;
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
