<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.place"
              :options="areaOptions"
              :props="{ value: 'value', label: 'name', children: 'city' }"
              filterable
              clearable
              placeholder="输入关键词可搜索"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              clearable
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目经理">
            <el-input
              v-model.trim="form.manager"
              placeholder="请输入项目经理姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="技术负责人">
            <el-input
              v-model.trim="form.technician"
              placeholder="请输入技术负责人姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="专职安全员">
            <el-input
              v-model.trim="form.secure"
              placeholder="请输入专职安全员姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标单位">
            <el-input
              v-model.trim="form.winner"
              placeholder="请输入中标单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建设单位">
            <el-input
              v-model.trim="form.owner"
              placeholder="请输入建设单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="金额">
            <div class="flex alc">
              <el-input
                v-model.number.trim="form.minBidAmount"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number.trim="form.maxBidAmount"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩类型">
            <el-select
              style="width: 100%"
              v-model="form.projectClass"
              placeholder="请选择业绩类型"
              clearable
            >
              <el-option
                v-for="item in projectClassOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目类型">
            <el-select
              style="width: 100%"
              v-model="form.projectType"
              placeholder="请选择项目类型"
              clearable
            >
              <el-option
                v-for="item in projectTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩来源">
            <el-select
              style="width: 100%"
              v-model="form.sourceName"
              placeholder="请选择业绩所在平台"
              clearable
            >
              <el-option
                v-for="item in sourceOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="项目名称" style="margin-bottom: 0px">
            <div
              class="mb22 flex alc"
              v-for="(item, index) in projectNameList"
              :key="'project' + index"
            >
              <div class="mr20 flex1">
                <el-input
                  placeholder="多关键词用空格隔开，如：园林 绿化"
                  v-model="item.keyword"
                  class="input-with-select"
                  clearable
                >
                  <el-select
                    style="width: 130px"
                    v-model="item.radio"
                    slot="prepend"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option label="包含" value="包含"></el-option>
                    <el-option label="不包含" value="不包含"></el-option>
                  </el-select>
                </el-input>
              </div>

              <div>
                <el-button
                  v-if="index == 0"
                  type="primary"
                  icon="el-icon-plus"
                  plain
                  circle
                  @click="addName"
                >
                </el-button>

                <el-button
                  v-else
                  @click="delName(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="tac">
        <el-button @click="reset" icon="el-icon-refresh-right">
          重置
        </el-button>

        <el-button type="primary" @click="query" icon="el-icon-search">
          查询
        </el-button>
      </div>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          条业绩信息
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="flex jcsb">
            <div
              class="name flex1 link"
              v-html="$options.filters.formatTitle(item.name, includesName)"
              @click="toDetail(item.id)"
            ></div>

            <div
              class="desc_item flexN c666"
              :title="
                '中标时间：' +
                (item.time && item.time != '-1' ? item.time : '--')
              "
            >
              <div class="left_text">中标时间：</div>
              <div class="right_content">
                {{ item.time | noVoid }}
              </div>
            </div>
          </div>

          <div class="desc mb20">
            <div
              class="desc_item flex3"
              :title="
                '中标单位：' +
                (item.winner && item.winner != '-1' ? item.winner : '--')
              "
            >
              <div class="left_text">中标单位：</div>
              <div class="right_content company">
                {{ item.winner | noVoid }}
              </div>
            </div>

            <div class="desc_item" :title="'中标金额：' + item.money">
              <div class="left_text">中标金额：</div>
              <div class="right_content">
                {{ item.money }}
              </div>
            </div>

            <div
              class="desc_item"
              :title="
                '中标地区：' +
                (item.place && item.place != '-1' ? item.place : '--')
              "
            >
              <div class="left_text">中标地区：</div>
              <div class="right_content">
                {{ item.place | noVoid }}
              </div>
            </div>
          </div>

          <div class="desc">
            <div
              class="desc_item flex3"
              :title="
                '建设单位：' +
                (item.owner && item.owner != '-1' ? item.owner : '--')
              "
            >
              <div class="left_text">建设单位：</div>
              <div class="right_content company">
                {{ item.owner | noVoid }}
              </div>
            </div>

            <div
              class="desc_item flex1"
              :title="
                '项目经理：' +
                (item.manager && item.manager != '-1' ? item.manager : '--')
              "
            >
              <div class="left_text">项目经理：</div>
              <div class="right_content">
                {{ item.manager | noVoid }}
              </div>
            </div>

            <div
              class="desc_item flex1"
              :title="
                '业绩类型：' +
                (item.projectClass && item.projectClass != '-1'
                  ? item.projectClass
                  : '--')
              "
            >
              <div class="left_text">业绩类型：</div>
              <div class="right_content">
                {{ item.projectClass | noVoid }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查业绩 */
// 引入ajax函数
import { searchResult } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";
import queryTypeTips from "../components/queryTypeTips";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        place: [], // 企业地区,
        minBidTime: "", // 中标时间-开始
        maxBidTime: "", // 中标时间-结束
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        winner: "", // 中标单位
        owner: "", // 建设单位
        minBidAmount: "", // 金额-最小
        maxBidAmount: "", // 金额-最大
        projectClass: "", // 业绩类型
        projectType: "", // 项目类型
        sourceName: "", // 来源平台
        contProNameKwList: [], // 包含的项目名称
        exclProNameKwList: [], // 不包含的项目名称
        pageNum: 1,
        pageSize: 20,
      },
      areaOptions: [], // 地区选项数据
      time: [], // 时间
      sourceOptions: [], // 业绩来源下拉数据
      projectClassOptions: [], // 业绩类型下拉数据
      projectTypeOptions: [], // 项目类型下拉数据
      // 项目名称
      projectNameList: [
        {
          radio: "",
          keyword: "",
        },
      ],
      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [], // 列表
      total: 0, // 总数
      paginationTotal: 0, // 分页总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  computed: {
    includesName() {
      return this.projectNameList
        .filter((v) => v.radio == "包含")
        .map((v) => v.keyword)
        .join(" ");
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const place = this.form.place.filter((v) => v != "").join("-");
      let minBidTime = "";
      let maxBidTime = "";
      if (this.time && this.time.length > 0) {
        minBidTime = this.time[0];
        maxBidTime = this.time[1];
      }
      let contProNameKwList = [];
      let exclProNameKwList = [];
      if (this.projectNameList.length > 0 && this.projectNameList[0].keyword) {
        contProNameKwList = this.projectNameList
          .filter((v) => v.radio == "包含" && v.keyword)
          .map((v) => v.keyword.trim())
          .join(" ")
          .split(" ");
        exclProNameKwList = this.projectNameList
          .filter((v) => v.radio == "不包含" && v.keyword)
          .map((v) => v.keyword.trim())
          .join(" ")
          .split(" ");
      } else {
        contProNameKwList = [];
        exclProNameKwList = [];
      }

      const params = {
        ...this.form,
        place,
        minBidTime,
        maxBidTime,
        contProNameKwList,
        exclProNameKwList,
      };

      const { code, data, msg } = await searchResult(params);
      if (code !== 0) return this.$message.error(msg);
      data.records.forEach((v) => {
        v["money"] = (v.amount / 10000).toFixed(2) + "万元";
      });
      this.list = data.records;
      this.total = data.total;
      this.paginationTotal = data.total > 2000 ? 2000 : data.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 业绩来源
      const sourceList = await getDict({ source: "sourceList" });
      this.sourceOptions = sourceList.data;

      // 业绩类型
      const projectClass = await getDict({ source: "combinedProjectClass" });
      this.projectClassOptions = projectClass.data;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "performanceDetail",
        params: { id },
      });
    },
    // 添加项目名称
    addName() {
      if (this.projectNameList.length < 5) {
        this.projectNameList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除项目名称
    delName(index) {
      this.projectNameList.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        place: [], // 企业地区,
        minBidTime: "", // 中标时间-开始
        maxBidTime: "", // 中标时间-结束
        manager: "", // 项目经理
        technician: "", // 技术负责人
        secure: "", // 专职安全员
        winner: "", // 中标单位
        owner: "", // 建设单位
        minBidAmount: "", // 金额-最小
        maxBidAmount: "", // 金额-最大
        projectClass: "", // 业绩类型
        projectType: "", // 项目类型
        sourceName: "", // 来源平台
        contProNameKwList: [], // 包含的项目名称
        exclProNameKwList: [], // 不包含的项目名称
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
      this.projectNameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 查询
    query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查业绩") {
        this.projectNameList[0].radio = "包含";
        this.projectNameList[0].keyword = name;
      }
      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  async activated() {
    this.__init__();
  },

  // 生命周期-创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  .list {
    background-color: #fff;

    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px dashed #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          flex: 2;
          display: flex;
          align-items: center;
          margin-right: 30px;
          .right_content {
            flex: 1;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }
        }
      }

      .company {
        cursor: pointer;
        &:hover {
          color: #1d89e3;
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2) {
        border-top: none;
        border-top: 1px solid #efefef;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
