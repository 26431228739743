<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="72px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
              filterable
              placeholder="输入关键词可搜索"
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="企业名称">
            <el-input
              v-model.trim="form.companyName"
              placeholder="请输入企业名称进行搜索"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="成立时间">
            <el-date-picker
              v-model="time1"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              clearable
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="到期时间">
            <el-date-picker
              v-model="time2"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions1"
              value-format="yyyy-MM-dd"
              clearable
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="资质分类" style="margin-bottom: 0px">
        <div class="flex">
          <div class="flex1 mr20">
            <div
              class="flex mb22"
              v-for="(item, index) in certType"
              :key="'project' + index"
            >
              <div class="flex1 mr20" :span="8">
                <el-cascader
                  v-model="item.value"
                  :options="certTypeOptions"
                  :props="{
                    value: 'allDesc',
                    label: 'desc',
                    children: 'children',
                    emitPath: false,
                  }"
                  clearable
                  filterable
                  placeholder="输入关键词可搜索"
                ></el-cascader>
              </div>

              <div>
                <el-button
                  v-if="index == 0"
                  type="primary"
                  icon="el-icon-plus"
                  plain
                  circle
                  @click="addCert"
                >
                </el-button>

                <el-button
                  v-else
                  @click="delCert(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </div>
            </div>
          </div>

          <div class="mr20">
            <el-radio-group v-model="form.subsection">
              <el-radio label="同时具备"></el-radio>
              <el-radio label="任意均可"></el-radio>
              <el-radio label="有且仅有"></el-radio>
            </el-radio-group>
          </div>

          <div>
            <el-checkbox v-model="form.isExpire">包含资质到期</el-checkbox>
          </div>
        </div>
      </el-form-item>
      <!-- </div> -->

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          家符合的企业
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="flex alc jcsb">
            <div class="c333 link" @click="toDetail(item.companyId)">
              {{ item.companyName | noVoid }}
            </div>

            <div class="c666 fz14">
              资质数量：
              <span class="highlight2">{{ item.certNum || "0" }}</span>
            </div>
          </div>

          <div
            class="flex"
            v-if="item.certNameList && item.certNameList.length > 0"
          >
            <div
              class="desc flex1"
              :style="{ height: item.showMore ? 'auto' : '26px' }"
            >
              <div
                class="desc_item singleLine"
                v-for="cert in item.certNameList"
              >
                {{ cert }}
              </div>
            </div>

            <div class="more fz14 mt10" @click="item.showMore = !item.showMore">
              <div v-if="item.certNameList && item.certNameList.length > 3">
                更多
                <i
                  :class="
                    item.showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查资质 */
// 引入ajax函数
import { searchCert } from "@/api/itemizedQuery";
import { sourceTree } from "@/api/common";
import { getDict } from "@/api/common";
import area from "@/utils/area"; // 引入区域json
import queryTypeTips from "../components/queryTypeTips";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        location: [], // 地区
        certType: "", // 资质分类
        isExpire: true, // 是否到期
        subsection: "同时具备", // 筛选条件
        createTimeMin: "", // 成立时间-开始
        createTimeMax: "", // 成立时间-结束
        endTimeMin: "", // 到期时间-开始
        endTimeMax: "", // 到期时间-结束
        companyName: "", // 企业名称
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      },

      areaOptions: [], // 地区选项数据
      time1: [], // 成立时间
      time2: [], // 到期时间
      certTypeOptions: [], // 资质分类选项数据
      certType: [{ value: "" }], // 资质分类
      queryTypeOptions: [], // 检索模式

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      pickerOptions1: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [], // 列表
      total: 0, // 总数
      paginationTotal: 0, // 分页总数（大于2000条时，只显示前2000条）
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const location = this.form.location.filter((v) => v != "").join("-");
      let createTimeMin = ""; // 成立时间-开始
      let createTimeMax = ""; // 成立时间-结束
      let endTimeMin = ""; // 到期时间-开始
      let endTimeMax = ""; // 到期时间-结束
      if (this.time1 && this.time1.length > 0) {
        createTimeMin = this.time1[0];
        createTimeMax = this.time1[1];
      }
      if (this.time2 && this.time2.length > 0) {
        endTimeMin = this.time2[0];
        endTimeMax = this.time2[1];
      }
      const certType = this.certType
        .filter((v) => v.value != "")
        .map((v) => v.value)
        .join(",");

      const params = {
        ...this.form,
        location,
        createTimeMin,
        createTimeMax,
        endTimeMin,
        endTimeMax,
        certType,
        total: this.total,
      };

      const { data } = await searchCert(params);
      data.records.forEach((v) => {
        v["showMore"] = false;
      });
      this.list = data.records;
      this.total = data.total;
      this.paginationTotal = data.total > 2000 ? 2000 : data.total;
      this.loading = false;
    },
    // 获取树形数据
    async getOptions() {
      // 资质分类
      const certTypeOptions = await sourceTree({ source: "certType" });
      this.certTypeOptions = certTypeOptions.data;

      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions.data;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      // this.$router.push({
      //   name: "certificationDetails",
      //   params: { id },
      // });
      this.$router.push({
        name: "companyDetails",
        params: { name: "资质等级" },
        query: { id },
      });
    },
    // 添加资质分类
    addCert() {
      if (this.certType.length < 5) {
        this.certType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除资质分类
    delCert(index) {
      this.certType.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 地区
        certType: "", // 资质分类
        isExpire: true, // 是否到期
        subsection: "同时具备", // 筛选条件
        createTimeMin: "", // 成立时间-开始
        createTimeMax: "", // 成立时间-结束
        endTimeMin: "", // 到期时间-开始
        endTimeMax: "", // 到期时间-结束
        companyName: "", // 企业名称
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.time1 = [];
      this.time2 = [];
      this.certType = [
        {
          value: "",
        },
      ];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查资质") {
        this.form.companyName = name;
      }

      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  activated() {
    this.__init__();
  },

  // 生命周期-创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .desc {
        display: flex;
        flex-wrap: wrap;
        color: #999;
        width: 100%;
        overflow: hidden;
        transition: all 0.5s;

        .desc_item {
          width: calc(100% / 3);
          flex: none;
          display: -webkit-box;
          margin-top: 10px;
        }
      }

      .more {
        user-select: none;
        cursor: pointer;
        color: #666;
        width: 46px;

        &:hover {
          color: #409eff;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
